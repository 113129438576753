<template>

  <section id="seguridad-form">

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="file" 
            class="color-secondary pe-1"
          />
          Completa los siguientes campos para publicar un documento.
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Datos del documento</h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Añade un título</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      maxlength="75" 
                      v-model="document_title" 
                      ref="albumTitle"
                    />
                    <div class="form-text text-end">
                      {{ document_title.length }} /75
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Seleciona una categoría</label>
                    <v-select 
                      class="selvue-custom"
                      :options="category_list" 
                      v-model="category_selected"
                      label="nombre"
                      :clearable="false"
                      placeholder="Seleccionar">
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Carga del documento
              <font-awesome-icon 
                  :icon="['fa-regular','circle-question']"
                  content="Aquí debes cargar un documento PDF"
                  v-tippy="{ arrow: true }"
                />
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="upload-image">
                  <label
                    v-if="!file_type"
                    class="upload-image-label upload-image-label-document"
                    for="input-file">
                    <span class="upload-image-label-icon">
                    </span>
                    <input 
                      type="file" 
                      id="input-file" 
                      @change="loadFiles($event)"
                      accept=".pdf"
                      style="display: none" 
                    />
                    <span class="upload-image-label-text">
                      <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                      Haz clic aquí para seleccionar un documento
                    </span>
                  </label>
                  <div 
                    class="upload-image-preview upload-image-preview-document" 
                    v-if="file_type !== null"
                  >
                    <button 
                      @click="deletePreview()"
                      class="upload-image-preview-remove"   
                    >
                      <font-awesome-icon icon="times"/>
                    </button>
                    {{
                      document_uploaded.name.length == 0
                        ? `Documento sin nombre`
                        : `${document_uploaded.name.substring(0, 30)}${
                            30 > document_uploaded.name.length ? "" : "..."
                          }`
                    }}
    
                    {{
                      Math.round(document_uploaded.size * 10) / 10 > 1
                        ? `${Math.round(document_uploaded.size * 10) / 10}Mb`
                        : ''
                    }}
                  </div>
                </div>
                <div class="alert alert-light border text-center p-2 mt-2">
                  <small>
                    <i class="fa-solid fa-circle-info"></i>
                    Peso máximo: 20MB, Formato: PDF
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                v-if="edit_file !== null"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="cancelEdit()"
              >
                Volver
              </button>
              <button
                v-if="edit_file === null"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="addSafetyDoc()"
                :disabled="!itemsFilled()"
              >
                Agregar
              </button>
              <button
                v-if="edit_file !== null"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="editSafetyDoc()"
                :disabled="!itemsChanged()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div> 

      </div>
    </div>

    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner" />
    
  </section>


</template>

<script>
import Status from "../Modales/Status.vue";
import { MODULOS, GET_DOC_ID } from "@/apollo/queries";
import { ADD_SAFETY_DOC, EDIT_SAFETY_DOC } from "@/apollo/mutations";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";

export default {
  components: { Status, Spinner },
  data() {
    return {
      document_title: "",
      open_select_category: false,
      category_list: [],
      category_selected: {
        nombre: "Selecciona una categoria",
        id: "",
      },
      toggle_switch_btn: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      image_uploaded: {
        base64: "",
        src: "",
        format: "",
      },
      document_uploaded: {
        pdf: "",
        pdf_prev: "",
        name: "",
        format: "",
        size: 0,
      },
      file_type: null,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      safety_module_id: "",
      show_spinner: false,
      edit_file: null,
      file_limit_mb: false,
      subtitle: "",
      doc_edit: {},
      no_internet: false,
    };
  },
  mounted() {
    this.getModulos();
    if (this.$route.name == "seguridad-edit") {
      this.subtitle = "Edita un documento";
    } else {
      this.subtitle =
        "Completa los siguientes campos para publicar un documento.";
    }
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.getDocToEdit(this.$route.params.id);
    }
  },
  computed: {
    ...mapState("safetyModule", ["safety_docs_list", "safety_cat_list"]),
  },
  methods: {
    ...mapActions("docsModule", [
      "addDocAction",
      "editDocAction",
      "getDocsList",
    ]),
    ...mapActions("safetyModule", ["getSafeCatsList"]),
    openSelectCategory() {
      this.open_select_category = !this.open_select_category;
    },
    selectCategory(cat) {
      this.category_selected.nombre = cat.nombre;
      this.category_selected.id = cat.id_categoria;
      setTimeout(() => {
        this.open_select_category = false;
      }, 50);
    },
    handleToggleSwitch() {
      if (this.toggle_switch_btn) {
        this.toggle_switch_btn = false;
      } else {
        this.toggle_switch_btn = true;
      }
    },
    cleanInputs() {
      this.toggle_switch_btn = false;
      this.category_selected.nombre = "Selección categoría";
      this.category_selected.id = "";
      this.document_title = "";
      this.file_type = null;
      this.deletePreview();
    },
    closeStatus() {
      this.open_modal_status = false;
      if (this.file_limit_mb) {
        this.document_uploaded = {
          pdf: "",
          pdf_prev: "",
          name: "",
          format: "",
        };
      } else {
        if (this.no_internet == false) {
          this.$router.push({
            name: "seguridad-list",
          });
        } else {
          this.no_internet = false;
        }
      }
    },
    // Agregar nuevo documento
    addSafetyDoc() {
      if (this.itemsFilled()) {
        this.show_spinner = true;
        var file;
        var type_file;
        if (this.file_type == "image") {
          file = this.image_uploaded.src;
          type_file = this.image_uploaded.format;
        }
        if (this.file_type == "application") {
          file = this.document_uploaded.pdf;
          type_file = this.document_uploaded.format;
        }
        this.$apollo
          .mutate({
            mutation: ADD_SAFETY_DOC,
            variables: {
              nombre: this.document_title,
              archivo: file,
              formato_archivo: type_file,
              id_empresa: this.id_empresa,
              id_categoria: this.category_selected.id_categoria,
              id_modulo: this.safety_module_id,
              id_creador: this.$ls.get("user").id_usuario,
            },
          })
          .then((res) => {
            var resp = res.data.agregarDocumento;
            this.show_spinner = false;
            if (resp.id_documento) {
              this.open_modal_status = true;
              this.modal_status = true;
              this.file_limit_mb = false;
              this.modal_status_msg =
                "Tú documento ha sido añadido correctamente";
              this.addDocAction(resp);
            }
            this.cleanInputs();
          })
          .catch((err) => {
            console.log("err", err);
            this.show_spinner = false;
            this.open_modal_status = true;
            this.modal_status = true;
            this.file_limit_mb = false;
            this.modal_status_msg = "";
          });
      }
    },
    loadFiles(event) {
      var files = event.target.files;
      var type = files[0].type.split("/")[0];
      var file_name = files[0].name;

      if (files.length > 0) {
        var reader = new FileReader();
        if (type == "image") {
          this.modal_status = false;
          this.open_modal_status = true;
          this.modal_status_msg = "Solo se aceptan archivos .pdf";
          this.modal_status = false;
          this.file_limit_mb = true;
        }
        if (type == "application") {
          setTimeout(() => {
            console.log("tamaño del archivo", files[0]);
            if (files[0].size < 20000000) {
              reader.onload = (e) => {
                var file_format = e.target.result.split(":")[1].split(";")[0];

                this.document_uploaded.pdf =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.document_uploaded.pdf_prev = e.target.result;
                this.document_uploaded.name = file_name;
                this.document_uploaded.format = file_format;
                this.document_uploaded.size = files[0].size / 1000000;
                this.file_type = "application";
              };
              reader.readAsDataURL(files[0]);
            } else {
              this.open_modal_status = true;
              this.file_limit_mb = true;
              this.modal_status = false;
              this.modal_status_msg = "El documento excede los 5MB";
            }
          }, 300);
        }
      }
    },
    deletePreview() {
      this.image_uploaded = {
        base64: "",
        src: "",
        format: "",
      };
      this.document_uploaded = {
        pdf: "",
        pdf_prev: "",
        name: "",
        format: "",
        size: 0,
      };
      this.file_type = null;
    },
    getModulos() {
      this.$apollo
        .query({
          query: MODULOS,
        })
        .then((res) => {
          var resp = res.data.modulos;
          resp.forEach((element) => {
            if (element.nombre_modulo == "Seguridad") {
              this.safety_module_id = element.id_modulo;
              var variables_graph = {
                id_empresa: this.id_empresa,
                id_modulo: element.id_modulo,
              };
              this.getSafeCatsList(variables_graph);
              setTimeout(() => {
                this.show_spinner = false;
                this.category_list = this.safety_cat_list;
              }, 2000);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // funcion que verifica que se haya llenado el formulario al crear un nuevo doc
    itemsFilled() {
      if (this.document_title.length > 0 && this.category_selected.id_categoria !== "") {
        if (
          this.image_uploaded.base64 !== "" ||
          this.document_uploaded.pdf !== ""
        ) {
          return true;
        } else false;
      } else false;
    },
    // se obtiene doc a editar
    async getDocToEdit(idDoc) {
      this.show_spinner = true;
      console.log('SAFETY',this.safety_docs_list);
      if (this.safety_docs_list) {
        if(this.safety_docs_list.length > 0){
          this.doc_edit = this.safety_docs_list.find(
          (doc) => doc.id_documento == idDoc
        );
        this.show_spinner = false;
        }
      } else {
        await this.$apollo
          .query({
            query: GET_DOC_ID,
            variables: {
              id_documento: idDoc,
              id_empresa: this.id_empresa,
            },
          })
          .then((res) => {
            var resp = res.data.FindDocumentoId;
            this.show_spinner = false;
            if (resp != null) {
              console.log("resp", resp);
              this.doc_edit = resp;
            } else {
              this.open_modal_status = true;
              this.modal_status = false;
              this.file_limit_mb = false;
              this.modal_status_msg =
                "Ha ocurrido un problema, intenta nuevamente";
            }
          })
          .catch((err) => {
            console.log("err", err);

            this.show_spinner = false;
            this.open_modal_status = true;
            this.modal_status = false;
            this.file_limit_mb = false;
            this.modal_status_msg =
              "Ha ocurrido un problema, intenta nuevamente";
          });
      }
      console.log("doc", this.doc_edit);
      if (this.doc_edit != {}) {
        var get_format = this.doc_edit.url_documento.slice(
          57,
          this.doc_edit.url_documento.length
        );
        this.edit_file = this.doc_edit;
        this.edit_file.format = get_format.split(".")[1]; //se obtiene formato del doc
        this.document_title = this.doc_edit.nombre;
        this.category_selected.nombre = this.doc_edit.categoria[0].nombre;
        this.category_selected.id_categoria = this.doc_edit.categoria[0].id_categoria;
      }

      // console.log(this.edit_file);

      // diferenciando el formato del documento
      if (
        this.edit_file.format == "jpeg" ||
        this.edit_file.format == "jpg" ||
        this.edit_file.format == "png"
      ) {
        // si doc es una imagen
        this.image_uploaded.base64 = this.edit_file.url_documento;
        this.image_uploaded.src = this.edit_file.url_documento;
        this.image_uploaded.format = this.edit_file.format;
        this.file_type = "image";
      } else {
        // si doc es un pdf u otro tipo de archivo
        this.document_uploaded.pdf = this.edit_file.url_documento;
        this.document_uploaded.pdf_prev = this.edit_file.url_documento;
        this.document_uploaded.format = this.edit_file.format;
        this.document_uploaded.name =
          this.edit_file.nombre + "." + this.edit_file.format;
        this.file_type = "application";
      }
    },
    // editar documento
    editSafetyDoc() {
      if (this.itemsChanged()) {
        this.show_spinner = true;
        var file;
        var type_file;
        if (this.file_type == "image") {
          type_file = this.image_uploaded.format;
          file =
            this.edit_file.url_documento !== this.image_uploaded.src
              ? this.image_uploaded.src
              : ""; // si no se cambia imagen se envia vacia
        }
        if (this.file_type == "application") {
          type_file = this.document_uploaded.format;
          file =
            this.edit_file.url_documento !== this.document_uploaded.pdf
              ? this.document_uploaded.pdf
              : ""; // si no se ha cambiado el documento se envia vacio
        }
        this.$apollo
          .mutate({
            mutation: EDIT_SAFETY_DOC,
            variables: {
              nombre: this.document_title,
              archivo: file,
              formato_archivo: type_file,
              id_empresa: this.id_empresa,
              id_categoria: this.category_selected.id_categoria,
              id_modulo: this.safety_module_id,
              id_modificador: this.$ls.get("user").id_usuario,
              id_documento: this.edit_file.id_documento,
            },
          })
          .then((res) => {
            var resp = res.data.editarDocumento;
            // console.log(resp);
            this.show_spinner = false;
            if (resp.id_documento !== null) {
              resp.id_modulo = this.safety_module_id;
              this.editDocAction(resp);

              this.open_modal_status = true;
              this.modal_status = true;
              this.file_limit_mb = false;
              this.modal_status_msg =
                "Tú documento ha sido editado correctamente";
            }
            this.cleanInputs();
          })
          .catch((err) => {
            this.no_internet = true;
            console.log("err", err);
            this.show_spinner = false;
            this.modal_status = false;
            this.open_modal_status = true;
            this.file_limit_mb = false;
            this.modal_status_msg =
              "No se ha podido editar el documento, intenta nuevamente";
          });
      }
    },
    cancelEdit() {
      this.$router.push({ name: "seguridad-list" }).catch(() => {
        return;
      });
    },
    // funcion que verifica que se hayan hecho cambios en el doc que se esta editando
    itemsChanged() {
      if (this.edit_file.nombre !== this.document_title) {
        return true;
      } else if (
        this.edit_file.categoria[0].id_categoria !== this.category_selected.id_categoria
      ) {
        return true;
      } else if (this.file_type == "application") {
        if (this.edit_file.url_documento !== this.document_uploaded.pdf) {
          return true;
        } else {
          this.image_uploaded.src = "";
          return false;
        }
      } else if (this.file_type == "image") {
        if (this.edit_file.url_documento !== this.image_uploaded.src) {
          return true;
        } else {
          this.document_uploaded.pdf = "";
          return false;
        }
      } else return false;
    },
    // funcion que obtiene la lista de documentos
    getDocList() {
      var variables_search = {
        id_empresa: this.id_empresa,
        id_categoria: "0", //Categoría: "Todas"
        id_modulo: this.safety_module_id,
        plataforma: "2",
        nombre: "",
      };
      this.getDocsList(variables_search); //llamada a vuex
    },
  },
};
</script>
